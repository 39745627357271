.deskTopWrapper {
  background: url(../../../assets/images/about.png);
  background-size: cover;

  /* or 'contain' based on your preference */
  background-repeat: no-repeat;
  background-color: #000000;
  width: 100%;
}

.deskTopContent {
  margin-top: 10px;
  margin-bottom: 10px;
}

.responsiveWrapper {
  display: none;
}

.mobileBG {
  display: none;
  background-color: #ffffff;
}

@media screen and (max-width: 560px) {
  .deskTopWrapper {
    display: none;
  }

  .responsiveWrapper {
    padding: 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: url(../../../assets/images/about.png);
  }

  .mobileBG {
    display: flex;
  }
}
