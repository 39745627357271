.deskTopWrapper {
  background: url(../../../assets/images/ztiBanner.webp);
  background-size: cover;

  /* or 'contain' based on your preference */
  background-repeat: no-repeat;
  width: 100%;
  //   height: 100vh;
}
@media screen and (max-width: 560px) {
  .deskTopWrapper {
    background: url(../../../assets/images/ztiBanner.webp);
    background-size: cover;

    /* or 'contain' based on your preference */
    background-repeat: no-repeat;
    width: 100%;
    //   height: 100vh;
  }
}
